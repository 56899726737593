import React from "react";
import {Link} from "gatsby";
import Hero from "../sections/freetrialthankyou/Hero";
import Wave from "../sections/home1/Wave";
import PageWrapper from "../components/PageWrapper";
import SEO from '../components/SEO';

const Contact = () => { 
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Thank you for your submission"
        />
        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/contact">Contact</Link>
              </li>
              </ol>
           </nav>
        </div>          
        <Hero className="position-relative pt-15 pt-md-31 pt-lg-25 pb-14 pb-md-19 pb-lg-33 position-relative overflow-hidden" />  
        <Wave color="#F7F9FC" />         
        <div className="grey d-flex align-items-center pt-0 pt-md-0 pt-lg-10 pb-8 pb-md-12 pb-lg-23">
          <div className="container">
            
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
